<template>
  <div class="sg-container">
    <el-row>
      <el-col
        :xs="{ span: 20, offset: 2 }"
        :sm="{ span: 6, offset: 9 }"
        :lg="{ span: 6, offset: 9 }"
      >
        <el-card class="box-card sg-login-card " shadow="always">
          <!-- <h1>VueE</h1> -->
          <div class="sg-flex-center">
            <img src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/logo-4.png" class="sg-icon-font sg-pad"/>
          </div>
          <el-form ref="ruleForm" :model="form" :rules="rules">
            <el-form-item prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入用户名"
                class="sg-login-input"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="form.password"
                placeholder="请输入密码"
                class="sg-login-input"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="form.remember">记住密码</el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('ruleForm')" class="sg-block"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>

import { mapState } from 'vuex'

export default {
  name: "Login",
  props: {
    msg: String,
  },
  computed: mapState({
    rememberedUserInfo: state => state.admin.rememberedUserInfo
  }),
  data: () => {
    return {
      form: {
        name: '',
        password: '',
        remember: false
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    if(this.rememberedUserInfo) this.form = this.rememberedUserInfo
  },
  methods: {
    onSubmit: function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submit()
        } else return false;
      });
    },
    submit() {
      this.$store.dispatch('admin/login', this.form);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-login-card {
  padding: 0 $pad-md;

  h1 {
    color: $main-color;
    text-align: center;
  }
}
.sg-container {
  min-height: 100vh;
  padding-top: 10%;
  box-sizing: border-box;
  background: url("https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/admin-bg.jpg") no-repeat;
  background-size: cover;
}
.el-card {
  border-radius: 0;
  border-width: 0;
  background: rgba(255, 255, 255, 1);
}
</style>
